// index.js

import { Elm } from './src/Main.elm'

var user = localStorage.getItem('user-save');
var postVoteCount = localStorage.getItem('vote-count-save');
var votes = localStorage.getItem('votes-save');
var authState = localStorage.getItem('auth-state-save')
var authMessage = localStorage.getItem('auth-message-save')


var flags = {
  "user": user,
  "postVoteCount": postVoteCount,
  "entries": votes,
  "authState": authState,
  "message": authMessage
};

console.log(flags)

var app = Elm.Main.init({
  node: document.querySelector('main'),
  flags: flags
});

app.ports.storeUser.subscribe(function (user) {
  localStorage.setItem('user-save', JSON.stringify(user));
});

app.ports.storeCount.subscribe(function (cnt) {
  localStorage.setItem('vote-count-save', JSON.stringify(cnt));
});

app.ports.storeAuthState.subscribe(function (authState) {
  localStorage.setItem('auth-state-save', JSON.stringify(authState));
  app.ports.storeAuthStateAck.send(authState)
});

app.ports.storeVotes.subscribe(function (votes) {
  localStorage.setItem('votes-save', JSON.stringify(votes));
});

app.ports.storeAuthMessage.subscribe(function (authMessage) {
  localStorage.setItem('auth-message-save', JSON.stringify(authMessage));
});